import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input('menuItem')
  menuItem;

  public openMenu = false;
  
  constructor() { }

  ngOnInit() {
  }

  setMenuOpen(){
    this.openMenu=true;
    console.log(this.openMenu);
  }

  setMenuClose(){
    this.openMenu=false;
    console.log(this.openMenu);
  }
}
