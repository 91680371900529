import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-servizi',
  templateUrl: './servizi.component.html',
  styleUrls: ['./servizi.component.scss']
})
export class ServiziComponent implements OnInit {

  openMenu = false;
  
  constructor() { 
    /*
    http.request('http://185.58.193.185/psallitebackendapi/web/index.php/menu/viewallwebsite/?id_website=1')
      .subscribe((res) => {
        //console.log(res['_body']);
        var respo = res['_body'];
        console.log(respo);
      });
    */
  }

  ngOnInit() {
  }

  setMenuOpen(){
    this.openMenu=true;
    console.log(this.openMenu);
  }

  setMenuClose(){
    this.openMenu=false;
    console.log(this.openMenu);
  }
}
