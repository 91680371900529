import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ServiziComponent } from './pages/servizi/servizi.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { PortfolioDetailComponent } from './pages/portfolio-detail/portfolio-detail.component';
import { ChisonoComponent } from './pages/chisono/chisono.component';
import { MediaComponent } from './pages/media/media.component';
import { MediaStampaComponent } from './pages/media-stampa/media-stampa.component';
import { MediaPremiComponent } from './pages/media-premi/media-premi.component';
import { ContattiComponent } from './pages/contatti/contatti.component';

const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'home', component: HomeComponent},
  { path: 'servizi', component: ServiziComponent},
  { path: 'portfolio', component: PortfolioComponent},
  { path: 'portfolio-detail/:idprogetto', component: PortfolioDetailComponent},
  { path: 'portfolio-detail/:idprogetto', component: PortfolioDetailComponent},
  { path: 'chisono', component: ChisonoComponent},
  { path: 'media', component: MediaComponent},
  { path: 'media-stampa', component: MediaStampaComponent},
  { path: 'media-premi', component: MediaPremiComponent},
  { path: 'contatti', component: ContattiComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
