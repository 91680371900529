import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-media-premi',
  templateUrl: './media-premi.component.html',
  styleUrls: ['./media-premi.component.scss']
})
export class MediaPremiComponent implements OnInit {

  openMenu=false;
  
  constructor() { }

  ngOnInit() {
  }

  setMenuOpen(){
    this.openMenu=true;
    console.log(this.openMenu);
  }

  setMenuClose(){
    this.openMenu=false;
    console.log(this.openMenu);
  }
}
