import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  objectKey = Object.keys; 

  public openMenu = false;

  public config: SwiperConfigInterface = {
    pagination: true,
    autoplay: true,
    speed: 1000,
  };

  public immaginiHome = {
    0:{ url:'assets/immagini/003.jpg'},
    1:{ url:'assets/immagini/004.jpg'},
    2:{ url:'assets/immagini/005.jpg'},
  }
    
  constructor() { }

  ngOnInit() {
  }

  setMenuOpen(){
    this.openMenu=true;
    console.log(this.openMenu);
  }

  setMenuClose(){
    this.openMenu=false;
    console.log(this.openMenu);
  }

}
