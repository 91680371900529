import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material'
import { ModalGalleryComponent } from '../../modal/modal-gallery/modal-gallery.component';

@Component({
  selector: 'app-portfolio-detail',
  templateUrl: './portfolio-detail.component.html',
  styleUrls: ['./portfolio-detail.component.scss']
})
export class PortfolioDetailComponent implements OnInit {

  objectKey = Object.keys; 

  public config: SwiperConfigInterface = {
    pagination: true,
    autoplay: true,
    speed: 1000,
  };

  public immaginiHome = {
    0:{ url:'assets/immagini/portfolio/bilancella/1.jpg'},
    1:{ url:'assets/immagini/portfolio/bilancella/2.jpg'},
    2:{ url:'assets/immagini/portfolio/bilancella/3.jpg'},
    3:{ url:'assets/immagini/portfolio/bilancella/4.jpg'},
    4:{ url:'assets/immagini/portfolio/bilancella/5.jpg'},
    5:{ url:'assets/immagini/portfolio/bilancella/6.jpg'},
    6:{ url:'assets/immagini/portfolio/bilancella/7.jpg'},
    7:{ url:'assets/immagini/portfolio/bilancella/8.png'},
    8:{ url:'assets/immagini/portfolio/bilancella/9.png'},
    9:{ url:'assets/immagini/portfolio/bilancella/10.jpg'},
    10:{ url:'assets/immagini/portfolio/bilancella/11.jpg'},
    11:{ url:'assets/immagini/portfolio/bilancella/12.jpg'},
  }

  public immaginiMotore = {
    0:{ url:'assets/immagini/portfolio/motore/0.png'},
    1:{ url:'assets/immagini/portfolio/motore/1.png'},
    2:{ url:'assets/immagini/portfolio/motore/2.png'},
    3:{ url:'assets/immagini/portfolio/motore/3.png'},
    4:{ url:'assets/immagini/portfolio/motore/4.png'},
    5:{ url:'assets/immagini/portfolio/motore/5.png'},
    6:{ url:'assets/immagini/portfolio/motore/6.png'},
    7:{ url:'assets/immagini/portfolio/motore/7.png'},
    8:{ url:'assets/immagini/portfolio/motore/8.png'},
  }

  public immaginiMotoreEarth = {
    0:{ url:'assets/immagini/portfolio/earth55/1.jpg'},
    1:{ url:'assets/immagini/portfolio/earth55/2.jpg'},
    2:{ url:'assets/immagini/portfolio/earth55/3.jpg'},
    3:{ url:'assets/immagini/portfolio/earth55/4.jpg'},
    4:{ url:'assets/immagini/portfolio/earth55/5.jpg'},
    5:{ url:'assets/immagini/portfolio/earth55/6.jpg'},
    6:{ url:'assets/immagini/portfolio/earth55/7.jpg'},
    7:{ url:'assets/immagini/portfolio/earth55/8.jpg'},
    8:{ url:'assets/immagini/portfolio/earth55/9.jpg'},
    9:{ url:'assets/immagini/portfolio/earth55/10.jpg'},
    10:{ url:'assets/immagini/portfolio/earth55/11.jpg'},
    11:{ url:'assets/immagini/portfolio/earth55/12.jpg'},
    12:{ url:'assets/immagini/portfolio/earth55/13.jpg'},
    13:{ url:'assets/immagini/portfolio/earth55/14.jpg'},
    14:{ url:'assets/immagini/portfolio/earth55/15.jpg'},
    15:{ url:'assets/immagini/portfolio/earth55/16.jpg'},
    16:{ url:'assets/immagini/portfolio/earth55/17.jpg'},
  }

  public idprogetto : string;
  openMenu=false;

  constructor(private route: ActivatedRoute, public dialog: MatDialog) { 
    this.route.params.subscribe((params: ParamMap) => {
      this.idprogetto = params['idprogetto'];
      console.log(this.idprogetto);
    });
  }

  ngOnInit() {
  }

  setMenuOpen(){
    this.openMenu=true;
    console.log(this.openMenu);
  }

  setMenuClose(){
    this.openMenu=false;
    console.log(this.openMenu);
  }

  openModalGallery(chiave) {
    let dialogRef = this.dialog.open(ModalGalleryComponent, { 
      width: '100vh',
      height: 'auto',
      data: {
        chiave: chiave,
      }
    })
    dialogRef.afterClosed().subscribe(result => {

    });
  }

}
