import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-gallery',
  templateUrl: './modal-gallery.component.html',
  styleUrls: ['./modal-gallery.component.scss']
})
export class ModalGalleryComponent implements OnInit {

  public immaginiHome = {
    0:{ url:'assets/immagini/portfolio/bilancella/1.jpg'},
    1:{ url:'assets/immagini/portfolio/bilancella/2.jpg'},
    2:{ url:'assets/immagini/portfolio/bilancella/3.jpg'},
    3:{ url:'assets/immagini/portfolio/bilancella/4.jpg'},
    4:{ url:'assets/immagini/portfolio/bilancella/5.jpg'},
    5:{ url:'assets/immagini/portfolio/bilancella/6.jpg'},
    6:{ url:'assets/immagini/portfolio/bilancella/7.jpg'},
    7:{ url:'assets/immagini/portfolio/bilancella/8.png'},
    8:{ url:'assets/immagini/portfolio/bilancella/9.png'},
    9:{ url:'assets/immagini/portfolio/bilancella/10.jpg'},
    10:{ url:'assets/immagini/portfolio/bilancella/11.jpg'},
    11:{ url:'assets/immagini/portfolio/bilancella/12.jpg'},
  }

  public chiave;

  constructor(public dialogRef: MatDialogRef<ModalGalleryComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
    this.chiave = data.chiave;
    console.log(this.chiave);
  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }
}
