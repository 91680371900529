import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-media-stampa',
  templateUrl: './media-stampa.component.html',
  styleUrls: ['./media-stampa.component.scss']
})
export class MediaStampaComponent implements OnInit {

  openMenu=false;

  constructor() { }

  ngOnInit() {
  }

  setMenuOpen(){
    this.openMenu=true;
    console.log(this.openMenu);
  }

  setMenuClose(){
    this.openMenu=false;
    console.log(this.openMenu);
  }

  scarica(){
    window.open('assets/immagini/media/STAMPA/2/Nautica.pdf');
  }
}
