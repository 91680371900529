import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// FLEX LAYOUT
import { FlexLayoutModule } from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

//GALLERY
import { NgxGalleryModule } from 'ngx-gallery';

//FIREBASE
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

//PAGE
import { HeaderComponent } from './modal/header/header.component';
import { FooterComponent } from './modal/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { ServiziComponent } from './pages/servizi/servizi.component';
import { ChisonoComponent } from './pages/chisono/chisono.component';
import { ContattiComponent } from './pages/contatti/contatti.component';
import { MediaComponent } from './pages/media/media.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { MenuComponent } from './modal/menu/menu.component';
import { PortfolioDetailComponent } from './pages/portfolio-detail/portfolio-detail.component';
import { MediaPremiComponent } from './pages/media-premi/media-premi.component';
import { MediaStampaComponent } from './pages/media-stampa/media-stampa.component';
import { ModalGalleryComponent } from './modal/modal-gallery/modal-gallery.component';


//SWIPER
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

// MATERIAL
import { MatCheckboxModule, MatDialogModule } from '@angular/material';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyADmLqYGOycNuUM-5ObGQ_HkhrKRwHGX04",
  authDomain: "zaccaroyachtdesign-4ae1a.firebaseapp.com",
  databaseURL: "https://zaccaroyachtdesign-4ae1a.firebaseio.com",
  projectId: "zaccaroyachtdesign-4ae1a",
  storageBucket: "zaccaroyachtdesign-4ae1a.appspot.com",
  messagingSenderId: "144335843939",
  appId: "1:144335843939:web:ac069af490f4ffd3"
};


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ServiziComponent,
    ChisonoComponent,
    ContattiComponent,
    MediaComponent,
    PortfolioComponent,
    MenuComponent,
    PortfolioDetailComponent,
    MediaPremiComponent,
    MediaStampaComponent,
    ModalGalleryComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    BrowserAnimationsModule,
    FlexLayoutModule,
    NgxGalleryModule,
    SwiperModule,
    MatCheckboxModule,
    MatDialogModule,
  ],
  providers: [
    {
      provide: SWIPER_CONFIG, 
      useValue: DEFAULT_SWIPER_CONFIG,
    },
  ],
  entryComponents:[
    ModalGalleryComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
