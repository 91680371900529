import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chisono',
  templateUrl: './chisono.component.html',
  styleUrls: ['./chisono.component.scss']
})
export class ChisonoComponent implements OnInit {

  openMenu=false;

  constructor() { }

  ngOnInit() {
  }
  
  setMenuOpen(){
    this.openMenu=true;
    console.log(this.openMenu);
  }

  setMenuClose(){
    this.openMenu=false;
    console.log(this.openMenu);
  }

}
